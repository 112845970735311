import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M0.282607 7.71437L3.96933 9.09037L5.39631 13.6795C5.48762 13.9735 5.84703 14.0821 6.08559 13.8871L8.14063 12.2118C8.35605 12.0362 8.66287 12.0275 8.88799 12.1909L12.5946 14.8819C12.8498 15.0674 13.2113 14.9276 13.2753 14.6192L15.9906 1.55837C16.0604 1.22152 15.7294 0.940506 15.4087 1.06456L0.278287 6.90141C-0.0950995 7.04541 -0.0918461 7.57405 0.282607 7.71437ZM5.16634 8.35789L12.3716 3.92019C12.5011 3.84067 12.6343 4.01576 12.5231 4.11891L6.57669 9.64637C6.36767 9.84093 6.23285 10.1013 6.19466 10.3839L5.9921 11.885C5.96527 12.0855 5.68373 12.1054 5.62842 11.9114L4.84938 9.17406C4.76015 8.86184 4.89018 8.52835 5.16634 8.35789Z"
        fill="#8990A5"
      />
    </Svg>
  )
}

export default Icon
