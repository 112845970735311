import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9957 0C8.95236 0 0 8.95417 0 19.9997C0 31.0452 8.95236 39.9994 19.9957 39.9994C23.9169 39.9994 27.5611 38.8795 30.648 36.9288C31.566 36.3966 32.6271 36.0966 33.7645 36.0966C36.319 36.0966 38.5146 37.633 39.4782 39.8322C39.5223 39.9328 39.6206 39.9998 39.7304 39.9998C39.8792 39.9998 39.9997 39.8792 39.9997 39.7305L39.9903 20.2047C39.991 20.1364 39.9913 20.0681 39.9913 19.9997C39.9913 8.95417 31.039 0 19.9957 0ZM11.0368 19.9998C11.0368 24.9486 15.0478 28.9604 19.9956 28.9604C24.9434 28.9604 28.9544 24.9486 28.9544 19.9998C28.9544 15.051 24.9434 11.0392 19.9956 11.0392C15.0478 11.0392 11.0368 15.051 11.0368 19.9998Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M19.9958 39.9994C23.9171 39.9994 27.5613 38.8794 30.6481 36.9288C31.5661 36.3966 32.6272 36.0965 33.7647 36.0965C36.3191 36.0965 38.5148 37.6329 39.4783 39.8322C39.5224 39.9328 39.6208 39.9998 39.7306 39.9998C39.8793 39.9998 39.9999 39.8792 39.9999 39.7304L39.9904 20.2047C39.9911 20.1364 39.9915 20.0681 39.9915 19.9997C39.9915 17.7737 39.6279 15.6327 38.9569 13.6328L28.3816 16.8401C28.7519 17.8227 28.9545 18.8876 28.9545 19.9998C28.9545 24.9486 24.9435 28.9604 19.9957 28.9604C15.0479 28.9604 11.0369 24.9486 11.0369 19.9998L1.59375 27.8369C4.64163 34.9872 11.7335 39.9994 19.9958 39.9994Z"
        fill="url(#paint1_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 19.9997C0 8.95417 8.95236 0 19.9957 0C31.039 0 39.9913 8.95417 39.9913 19.9997C39.9913 20.0681 39.991 20.1364 39.9903 20.2047L39.9997 39.7305C39.9997 39.8792 39.8792 39.9998 39.7304 39.9998C39.6206 39.9998 39.5223 39.9328 39.4782 39.8322C38.5146 37.633 36.319 36.0966 33.7645 36.0966C32.6271 36.0966 31.566 36.3966 30.648 36.9288C27.5611 38.8795 23.9169 39.9994 19.9957 39.9994C8.95236 39.9994 0 31.0452 0 19.9997ZM21.5025 30.694C27.2952 30.694 31.9911 25.9972 31.9911 20.2033C31.9911 19.7103 31.957 19.2253 31.8913 18.7503L28.8946 18.9589C28.3789 14.5005 24.5914 11.0392 19.9956 11.0392C15.0478 11.0392 11.0368 15.051 11.0368 19.9998C11.0368 20.0673 11.0376 20.1345 11.0391 20.2016L11.0139 20.2033C11.0139 25.9972 15.7098 30.694 21.5025 30.694Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M39.9996 39.7304C39.9996 39.8792 39.879 39.9998 39.7303 39.9998C39.6205 39.9998 39.5221 39.9328 39.4781 39.8322C38.5145 37.6329 36.3188 36.0966 33.7644 36.0966C32.6269 36.0966 31.5658 36.3966 30.6478 36.9288C27.561 38.8794 23.9168 39.9994 19.9955 39.9994C14.0774 39.9994 8.75975 37.4278 5.09863 33.3409L14.3325 27.8605C16.208 29.6179 18.7294 30.694 21.5021 30.694C25.6994 30.694 29.3209 28.228 30.9973 24.6655H39.9923L39.9996 39.7304Z"
        fill="url(#paint3_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9957 0C8.95236 0 0 8.95417 0 19.9997C0 31.0452 8.95236 39.9994 19.9957 39.9994C22.1234 39.9994 24.1695 39.6697 26.0893 39.0564C17.3496 39.0178 10.2767 31.9196 10.2767 23.1691C10.2767 20.7139 10.8335 18.3888 11.8277 16.3132H11.8278C13.2331 13.2033 16.3617 11.0392 19.9956 11.0392C23.6295 11.0392 26.7581 13.2033 28.1635 16.3132H33.4456L37.0195 11.5736C37.7593 12.267 38.4328 13.0305 39.0293 13.8533C36.4366 5.81498 28.8951 0 19.9957 0Z"
        fill="#C4C4C4"
      />
      <path
        d="M19.9957 0C8.95236 0 0 8.95417 0 19.9997C0 31.0452 8.95236 39.9994 19.9957 39.9994C22.1234 39.9994 24.1695 39.6697 26.0893 39.0564C17.3496 39.0178 10.2767 31.9196 10.2767 23.1691C10.2767 20.7139 10.8335 18.3888 11.8277 16.3132C13.233 13.2032 16.3616 11.0392 19.9955 11.0392C23.6295 11.0392 26.7581 13.2032 28.1634 16.3132H33.4456L37.0195 11.5736C37.7593 12.267 38.4328 13.0305 39.0293 13.8533C36.4366 5.81498 28.8951 0 19.9957 0Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M20.1462 35.0885C23.1096 35.0885 25.7509 33.7093 27.4636 31.5577L23.5796 38.6492L20.1462 38.9826L13.1376 35.0885C11.7552 33.6211 8.99039 30.6346 8.99039 30.4272C8.99039 30.2198 10.5427 29.2715 11.3189 28.8233C12.5931 32.4714 16.0641 35.0885 20.1462 35.0885Z"
        fill="url(#paint5_linear)"
      />
      <g opacity="0.7">
        <path
          d="M19.9957 0C8.95236 0 0 8.95417 0 19.9997C0 31.0452 8.95236 39.9994 19.9957 39.9994C22.1234 39.9994 24.1695 39.6697 26.0893 39.0564C17.3496 39.0178 10.2767 31.9196 10.2767 23.1691C10.2767 20.7139 10.8335 18.3888 11.8277 16.3132C13.233 13.2032 16.3616 11.0392 19.9955 11.0392C23.6295 11.0392 26.7581 13.2032 28.1634 16.3132H33.4456L37.0195 11.5736C37.7593 12.267 38.4328 13.0305 39.0293 13.8533C36.4366 5.81498 28.8951 0 19.9957 0Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M20.1462 35.0885C23.1096 35.0885 25.7509 33.7093 27.4636 31.5577L23.5796 38.6492L20.1462 38.9826L13.1376 35.0885C11.7552 33.6211 8.99039 30.6346 8.99039 30.4272C8.99039 30.2198 10.5427 29.2715 11.3189 28.8233C12.5931 32.4714 16.0641 35.0885 20.1462 35.0885Z"
          fill="url(#paint7_linear)"
        />
      </g>
      <path
        opacity="0.4"
        d="M18.4136 39.9378C8.10985 39.1312 0 30.5128 0 19.9998C0 14.1168 2.53962 8.82703 6.58213 5.16748L8.69125 6.74861C4.95119 9.94354 2.58009 14.6947 2.58009 19.9998C2.58009 27.0216 6.73404 33.0731 12.7181 35.8298C14.6033 36.6201 19.264 38.2401 22.8256 38.3973C26.3872 38.5545 22.423 39.5309 19.9957 39.9995C19.4632 39.9995 18.9356 39.9787 18.4136 39.9378Z"
        fill="url(#paint8_radial)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.399272 23.9966L0.47506 24.2407C0.262664 23.2562 0.150822 22.2343 0.150822 21.1862C0.150822 13.2262 6.60236 6.77338 14.5607 6.77338C19.7039 6.77338 24.2178 9.46842 26.7676 13.5236L23.3539 5.32656L14.5607 4.19141L5.22809 6.51602C1.98072 10.0719 0 14.8047 0 20.0002C0 21.3688 0.137442 22.7053 0.399272 23.9966Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M4.59766 7.2397C8.2654 2.81687 13.8016 0 19.9956 0C31.0389 0 39.9912 8.95417 39.9912 19.9997C39.9912 20.0681 39.9909 20.1364 39.9902 20.2047L39.9996 39.7305C39.9996 39.8792 39.8791 39.9998 39.7303 39.9998C39.6205 39.9998 39.5222 39.9328 39.4781 39.8322C38.5145 37.633 36.3189 36.0966 33.7644 36.0966C32.627 36.0966 31.5659 36.3966 30.6479 36.9288C27.561 38.8795 23.9168 39.9994 19.9956 39.9994C18.607 39.9994 17.2514 39.8578 15.9426 39.5883C23.6616 37.8712 29.4328 30.9816 29.4328 22.7439C29.4328 13.2137 21.7086 5.48799 12.1804 5.48799C9.45978 5.48799 6.88627 6.11784 4.59766 7.2397Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M10.9299 37.8304C13.2072 38.991 15.7363 39.7281 18.4136 39.9377C18.9356 39.9785 19.4631 39.9994 19.9956 39.9994C20.2539 39.9495 20.5299 39.8938 20.8121 39.8341L20.0562 38.0969C18.784 38.7718 17.3329 39.1542 15.7924 39.1542C14.0305 39.1542 12.3853 38.654 10.9912 37.7878L10.9299 37.8304Z"
        fill="url(#paint11_linear)"
      />
      <path
        opacity="0.4"
        d="M39.9902 20.2047C39.9909 20.1364 39.9912 20.0681 39.9912 19.9997C39.9912 8.95417 31.0389 0 19.9956 0C17.5858 0 15.2756 0.426369 13.1362 1.2078C12.8136 1.32562 12.4949 1.45152 12.1804 1.58525C9.21884 2.84423 6.62329 4.79704 4.59766 7.2397C7.33994 5.39699 15.1111 2.34656 24.2573 4.88661C30.8548 6.74403 35.6911 12.807 35.6911 19.9997C35.6911 20.0537 35.6908 20.1076 35.6903 20.1615L35.6901 20.1841L35.6958 31.9722L35.6979 36.4022C36.101 36.5336 36.4861 36.705 36.8485 36.9116C37.9777 37.5557 38.8863 38.5425 39.4324 39.7305C39.4479 39.7641 39.4632 39.7982 39.4781 39.8322C39.5222 39.9328 39.6205 39.9998 39.7303 39.9998C39.8791 39.9998 39.9996 39.8792 39.9996 39.7305L39.9902 20.2047Z"
        fill="url(#paint12_radial)"
      />
      <path
        d="M19.9956 0C31.0389 0 39.9912 8.95417 39.9912 19.9997L39.9902 20.2047L39.9984 37.1457H38.2783L38.2702 20.1965L38.2712 19.9997C38.2712 9.90432 30.0889 1.7204 19.9956 1.7204C18.7218 1.7204 17.4785 1.85064 16.2783 2.09859C15.2832 2.32802 14.2552 2.64374 13.2327 3.01235C9.81115 4.24588 6.45177 6.07185 4.59766 7.2397C5.48338 6.17163 6.47807 5.19722 7.56467 4.33351C10.9776 1.62065 15.2974 0 19.9956 0Z"
        fill="url(#paint13_radial)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="29.7936"
          y1="39.9998"
          x2="11.8863"
          y2="27.8444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#069F5F" />
          <stop offset="1" stop-color="#007E49" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="28.5252"
          y1="27.488"
          x2="9.33588"
          y2="37.4284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#005531" />
          <stop offset="1" stop-color="#005531" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="29.7936"
          y1="39.9998"
          x2="11.8863"
          y2="27.8444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0FA968" />
          <stop offset="1" stop-color="#088E56" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="27.4781"
          y1="35.4679"
          x2="12.96"
          y2="32.3339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#005531" />
          <stop offset="1" stop-color="#005531" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="10.9303"
          y1="33.7645"
          x2="7.7404"
          y2="14.5549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0FA968" />
          <stop offset="1" stop-color="#0D995E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="10.9303"
          y1="33.7645"
          x2="7.7404"
          y2="14.5549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0FA968" />
          <stop offset="1" stop-color="#0D995E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="11.0192"
          y1="28.292"
          x2="14.8776"
          y2="9.78867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#008C51" stop-opacity="0" />
          <stop offset="1" stop-color="#00643A" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="11.0192"
          y1="28.292"
          x2="14.8776"
          y2="9.78867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#008C51" stop-opacity="0" />
          <stop offset="1" stop-color="#00643A" />
        </linearGradient>
        <radialGradient
          id="paint8_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-5.80219 32.9475) rotate(-56.5654) scale(26.0211 18.1156)"
        >
          <stop stop-color="#8FFED0" />
          <stop offset="1" stop-color="#8FFED0" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint9_linear"
          x1="22.8086"
          y1="22.5404"
          x2="21.2121"
          y2="6.74311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#13AC6C" />
          <stop offset="1" stop-color="#15BF78" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="34.7635"
          y1="36.6076"
          x2="30.02"
          y2="5.49208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#24BA7B" />
          <stop offset="1" stop-color="#33D993" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="34.7635"
          y1="36.6076"
          x2="30.02"
          y2="5.49208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#24BA7B" />
          <stop offset="1" stop-color="#33D993" />
        </linearGradient>
        <radialGradient
          id="paint12_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.9364 8.9234) rotate(59.3695) scale(24.6582 21.8261)"
        >
          <stop stop-color="#91FFD1" />
          <stop offset="1" stop-color="#90FFD1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.5817 4.086) rotate(67.4688) scale(25.8798 23.2843)"
        >
          <stop stop-color="#A6FFD9" />
          <stop offset="1" stop-color="#A6FFD9" stop-opacity="0" />
        </radialGradient>
      </defs>
    </Svg>
  )
}

export default Icon
