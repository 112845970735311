import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 25" fill="none" {...props}>
      <circle
        cx="9.33292"
        cy="10.3305"
        r="7.11222"
        stroke="#8990A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9.33297"
        cy="10.3305"
        r="3.55611"
        stroke="#8990A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M19.1122 10.1123C20.7382 11.4158 21.7793 13.4186 21.7793 15.6646C21.7793 19.5926 18.5951 22.7769 14.6671 22.7769C12.2867 22.7769 10.1794 21.6074 8.88843 19.8117"
        stroke="#8990A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17.7476 13.8866C18.0502 14.4097 18.2233 15.0169 18.2233 15.6647C18.2233 17.6286 16.6312 19.2208 14.6672 19.2208C14.0195 19.2208 13.4122 19.0476 12.8892 18.745"
        stroke="#8990A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M20.0724 9.95528C19.7716 9.67046 19.2969 9.68337 19.0121 9.98412C18.7273 10.2849 18.7402 10.7596 19.0409 11.0444L20.0724 9.95528ZM10.0467 20.0386C9.76193 19.7378 9.28723 19.7249 8.98648 20.0097C8.68573 20.2946 8.67282 20.7693 8.95765 21.07L10.0467 20.0386ZM21.0292 15.6646C21.0292 19.1784 18.1808 22.0269 14.667 22.0269V23.5269C19.0092 23.5269 22.5292 20.0068 22.5292 15.6646H21.0292ZM19.0409 11.0444C20.2664 12.2049 21.0292 13.8451 21.0292 15.6646H22.5292C22.5292 13.4163 21.5845 11.3873 20.0724 9.95528L19.0409 11.0444ZM14.667 22.0269C12.8474 22.0269 11.2073 21.264 10.0467 20.0386L8.95765 21.07C10.3897 22.5821 12.4186 23.5269 14.667 23.5269V22.0269Z"
        fill="#8990A5"
      />
    </Svg>
  )
}

export default Icon
